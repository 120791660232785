import React from 'react';
import PropTypes from 'prop-types';
import { PageSEO, useSEO } from '../components/SEO';
import Section01 from '../page-sections/about-us/Section01';

const AboutUs = ({ location }) => {
  //
  const { pathname } = location;
  const seo = useSEO(pathname);

  return (
    <article>
      <PageSEO title={seo.title} description={seo.description} pathname={pathname} featureImage="og-dafault.jpg" />
      <Section01 />
    </article>
  );
};

export default AboutUs;

AboutUs.propTypes = {
  location: PropTypes.object.isRequired,
};
