import React from 'react';
import styled from 'styled-components';
import { Container } from '../../components/Elements';

const Section = styled.section`
  padding: 4rem 0 8rem;

  color: ${({ theme }) => theme.onLightColorHighEmphasis};

  p {
    margin-bottom: 1rem;
    line-height: 1.75;
  }

  .page-width {
    max-width: 42.5rem;
    width: 100%;
    margin: 0 auto;
  }

  .subtitle {
    margin-top: 1rem;
    color: ${({ theme }) => theme.onLightColorMediumEmphasis};
  }

  .heading {
    color: ${({ theme }) => theme.primaryColor};
    line-height: 1.35;
    margin-bottom: 2rem;
  }

  .subheading {
    color: ${({ theme }) => theme.primaryColor};
    line-height: 1.35;
    margin-bottom: 0.5rem;
  }

  .end-paragraph {
    margin-bottom: 4rem;
  }

  .end-paragraph-sm {
    margin-bottom: 2rem;
  }
`;

const Section01 = () => {
  //
  return (
    <Section>
      <Container>
        <div className="page-width">
          <h1 className="roboto-text-2xl heading">About Tandoori Zaika</h1>
          <p className="roboto-text-base">
            The passion for food brings 3 food lovers & friends together, to deliver quality dining with authentic and
            real flavours of Indian food to residents and visitors of Singapore (City in a Garden).
          </p>
          <p className="roboto-text-base">
            Tandoori Zaika was formed based on the core values of Atithi Devo Bhava [अतिथिदेवो भव] , authenticity [real
            Zaika's (flavours)], integrity, passion, quality, consistency and customer experience.
          </p>
          <p className="roboto-text-base">
            Our restaurant is based in Boat Quay Singapore along the bank of Singapore River, providing alfresco dining
            and great ambience with view of the{' '}
            <a
              href="https://en.wikipedia.org/wiki/Parliament_House,_Singapore"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              Parliament House
            </a>
            ,{' '}
            <a
              href="https://en.wikipedia.org/wiki/Empress_Place_Building"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              Empress Place Building
            </a>
            ,{' '}
            <a
              href="https://en.wikipedia.org/wiki/The_Fullerton_Hotel"
              className="link"
              target="_blank"
              rel="noreferrer"
            >
              The Fullerton Hotel
            </a>{' '}
            and{' '}
            <a href="https://en.wikipedia.org/wiki/Marina_Bay_Sands" className="link" target="_blank" rel="noreferrer">
              Marina Bay Sands.
            </a>
          </p>
          <p className="roboto-text-base end-paragraph">We prepare our food with passion and serve with love.</p>

          <h2 className="roboto-text-2xl heading" id="core-1">
            Our Core Values
          </h2>
          <h3 className="roboto-text-lg subheading">ATITHI DEVO BHAVA [अतिथिदेवो भव]</h3>
          <p className="roboto-text-base end-paragraph-sm">
            Atithi Devo Bhava, also spelled Atithidevo Bhava{' '}
            <a className="link" href="https://en.wikipedia.org/wiki/Sanskrit_language" target="_blank" rel="noreferrer">
              Sanskrit
            </a>
            : अतिथिदेवो भव, Be the one who considers that Guests are equivalent to God), prescribes a dynamic of the
            host–guest relationship which embodies the traditional{' '}
            <a className="link" href="https://en.wikipedia.org/wiki/Indian_religions" target="_blank" rel="noreferrer">
              Indian Hindu-Buddhist philosophy
            </a>{' '}
            of revering guests with same respect as god. This concept of going out of the way to treat guests with
            reverence goes even beyond the traditional Hindu-Buddhist common greeting of{' '}
            <a href="https://en.wikipedia.org/wiki/Namaste" className="link" target="_blank" rel="noreferrer">
              namaste
            </a>{' '}
            (I bow to the divinity in you) used for every one.
          </p>
          <h3 className="roboto-text-lg subheading" id="core-2">
            AUTHENTICITY
          </h3>
          <p className="roboto-text-base end-paragraph-sm">
            Authentic cuisine and Zaika's (flavours) - cuisine that reflects Indian traditions & flavours, that is made
            with intention, care and knowledge.
          </p>
          <h3 className="roboto-text-lg subheading" id="core-3">
            INTEGRITY
          </h3>
          <p className="roboto-text-base end-paragraph-sm">
            Being truthful, sincere, and upfront with everyone you interact with.
          </p>
          <h3 className="roboto-text-lg subheading" id="core-4">
            PASSION
          </h3>
          <p className="roboto-text-base end-paragraph-sm">
            Love and believe in what you do including the way we cook, serve, interact, etc…
          </p>
          <h3 className="roboto-text-lg subheading" id="core-5">
            QUALITY & CONSISTENCY
          </h3>
          <p className="roboto-text-base end-paragraph-sm">
            Maintain quality and consistency in all that we do including food, flavours & service.
          </p>
          <h3 className="roboto-text-lg subheading" id="core-6">
            CUSTOMER EXPERIENCE
          </h3>
          <p className="roboto-text-base end-paragraph-sm">
            Create a memorable experience for our Guests and each other by providing great ambience, food, service and
            by “treating everyone in a warm, friendly, genuine manner, as you would Family and Friends.”
          </p>
        </div>
      </Container>
    </Section>
  );
};

export default Section01;

Section01.propTypes = {
  //
};

Section01.defaultProps = {
  //
};
